import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { currencyCodes } from "src/constants/variables";

const CurrencySelector = ({
  setCurrency,
  selected,
  width,
  maxHeight,
  setIsopen,
  inHeight,
}: {
  setIsopen?: any;
  maxHeight?: string;
  setCurrency: any;
  selected: string;
  width: string;
  inHeight: string;
}) => {
  const { t } = useTranslation();
  const [showCurr, setShowCurr] = useState<boolean>(false);
  const [InputCurr, setInputCurr] = useState<string>("");
  const [filteredCurren, setFilteredCurren] = useState<any[]>([]);

  const handleClicked = (event: any) => {
    event.stopPropagation();
    setShowCurr(true);
    setIsopen(true);
  };

  useEffect(() => {
    document.addEventListener("click", () => {
      setShowCurr(false);
      setIsopen(false);
    });
  }, []);

  const handleCurClick = (item: string) => {
    setShowCurr(false);
    setIsopen(false);
    setInputCurr(item);
    setCurrency(item);
  };

  useEffect(() => {
    let alldata: any[] = currencyCodes;
    if (InputCurr) {
      alldata = alldata.filter((item: string) =>
        item.includes(InputCurr.toUpperCase())
      );
    }
    setFilteredCurren(alldata);
  }, [InputCurr]);

  useEffect(() => {
    setInputCurr(selected);
  }, [selected]);

  return (
    <Box width={width} position={"relative"}>
      <Stack>
        <input
          style={{ width: width, height: inHeight }}
          value={InputCurr}
          type="text"
          placeholder={t("locationmanager.Currency")}
          className="addPlayer"
          onClick={handleClicked}
          onChange={(e) => setInputCurr(e.target.value)}
        />
      </Stack>
      {showCurr && (
        <Box
          position={"absolute"}
          zIndex={"99"}
          sx={{
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            background: "white",
            width: width,
            marginTop: "0px !important",
            maxHeight: maxHeight ? maxHeight : "273px",
          }}
          id="table-person"
        >
          {filteredCurren?.map((item: string, index: number) => (
            <Box
              key={index}
              onClick={() => handleCurClick(item)}
              sx={{
                padding: "10px 15px",
                background: "#889AB61A",
                color: "#22356D",
                "&:hover": { background: "#22356D", color: "white" },
                cursor: "pointer",
              }}
            >
              {item}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default CurrencySelector;
