import { createBrowserRouter, Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import React, { Suspense, useContext, useEffect } from "react";
import MainContext from "src/context/MainContext";
import SignIn from "../auth/login";
import SuperAdminSettings from "src/pages/SuperAdminSettings";
import Logout from "src/pages/Logout";
import LocationManager from "src/features/Admin/LocationManager";
import Loading from "src/constants/Loading";

const MainPage = React.lazy(() => import("../pages/MainPage"));
const CalenderPage = React.lazy(() => import("../pages/CalenderPage"));
const Services = React.lazy(() => import("../pages/Services"));
const CourtsSetting = React.lazy(() => import("../pages/CourtsSettings"));
const Payments = React.lazy(() => import("src/pages/Payments"));
const Customers = React.lazy(() => import("src/pages/Customers"));
const Statistics = React.lazy(() => import("src/pages/Statistics"));
const ErrorElement = React.lazy(() => import("src/pages/ErrorElement"));
const POS = React.lazy(() => import("src/pages/POS"));

const RequireAuth: React.FC<any> = ({ children }) => {
  const context = useContext(MainContext);
  const authenticated = context?.authenticated;

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      const decodedToken: any = jwtDecode(token);

      if (decodedToken.exp * 1000 < Date.now()) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userData");
        window.location.href = "/signin";
      }
    }
  }, [context]);

  if (!authenticated) {
    return <Navigate to="/signin" />;
  }

  return children;
};

const RequireAdminRole: React.FC<any> = ({ children }) => {
  const user_data = JSON.parse(localStorage.getItem("user") || "");

  const userRole = user_data.role.name;

  if (userRole === "SUPER_ADMIN") {
    return children;
  } else {
    return <LocationManager />;
  }
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <RequireAuth>
        <Navigate to="/calendars" />
      </RequireAuth>
    ),
  },
  {
    path: "/",
    errorElement: <ErrorElement />,
    element: (
      <RequireAuth>
        <MainPage />
      </RequireAuth>
    ),
    children: [
      {
        path: "calendars",
        element: (
          <Suspense fallback={<Loading />}>
            {" "}
            <CalenderPage />{" "}
          </Suspense>
        ),
      },
      { path: "logout", element: <Logout /> },
      // { path: "bookings", element: <Bookings /> },
      {
        path: "payments",
        element: (
          <Suspense fallback={<Loading />}>
            {" "}
            <Payments />
          </Suspense>
        ),
      },
      {
        path: "courts",
        element: (
          <Suspense fallback={<Loading />}>
            <CourtsSetting />{" "}
          </Suspense>
        ),
      },
      {
        path: "customers",
        element: (
          <Suspense fallback={<Loading />}>
            <Customers />
          </Suspense>
        ),
      },
      {
        path: "services",
        element: (
          <Suspense fallback={<Loading />}>
            <Services />{" "}
          </Suspense>
        ),
      },
      {
        path: "stats",
        element: (
          <Suspense fallback={<Loading />}>
            {" "}
            <Statistics />{" "}
          </Suspense>
        ),
      },
      {
        path: "POS",
        element: (
          <Suspense fallback={<Loading />}>
            {" "}
            <POS />{" "}
          </Suspense>
        ),
      },
      {
        path: "Settings",
        element: (
          <RequireAdminRole>
            <SuperAdminSettings />
          </RequireAdminRole>
        ),
      },
    ],
  },
  {
    path: "/signin",
    element: <SignIn />,
  },
  // Redirect for any non-existing route
  {
    path: "*",
    element: <Navigate to="/calendars" />,
  },
]);

export default router;
