import React from "react";

export type courtCoachType = { [key: string]: string[] };

export type rateDetails = {
  bookingPercent: number;
  eventPercent: number;
  lessonPercent: number;
  noShowCounts: number;
  openMatchCounts: number;
  pendingPayments: number;
  unfilledOpenMatchCounts: number;
  dailyOccupancyRate: string;
  dailyOccupancyHours: number;
};

export interface coachmonthtstats {
  occupancyRate: number;
  overallTotalEventsHours: number;
  eventPercent: number;
  overallTotalLessonsHours: number;
  lessonsPercent: number;
}

export type monthlyRate = {
  bookingPercentage: number;
  lessonPercentage: number;
  eventPercentage: number;
  openMatchCounts: number;
  totalPendingPayments: number;
};

export type userData = {
  id: number;
  name: string;
  duration: number;
};

export type courtMonthlyStats = {
  occupiedHours: number;
  occupancyRate: number;
};

export type weeklyRate = {
  coachesDetails: userData[];
  noShowCounts: number;
  pendingPayments: number;
};

export type NotificationType = {
  message: string;
  type: string;
  subMessage: string;
  isShow: boolean;
};

export type locationTypes = {
  id: number;
  location_name: string;
  currency: string;
};

// Define the type for your context
export interface MainContextType {
  UpdatePayments: boolean;
  chatURL: string;
  setChatURL: React.Dispatch<React.SetStateAction<string>>;
  SelectedLocation: locationTypes;
  setSelectedLocation: React.Dispatch<React.SetStateAction<locationTypes>>;
  setCourtMonthlystats: React.Dispatch<React.SetStateAction<courtMonthlyStats>>;
  courtMonthlyStats: courtMonthlyStats;
  setUpdatePayments: React.Dispatch<React.SetStateAction<boolean>>;
  authenticated: boolean;
  selectedLocationId: number;
  setMobileSideBar: React.Dispatch<React.SetStateAction<boolean>>;
  MobileSideBar: boolean;
  setSelectedLocationId: any;
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCustomerid: any;
  setSelectedCustomerId: any;
  resServicesList: any;
  setServicesList: any;
  sideBar: boolean;
  sport_id: any;
  setSport_id: any;
  setLoginData: any;
  LoginData: any;
  setSelectedCourtDurations: any;
  SelectedCourtDurations: any;
  setSideBar: React.Dispatch<React.SetStateAction<boolean>>;
  ActiveTab: string;
  selectedMonth: any;
  setSelectedMonth: any;
  setNotification: React.Dispatch<React.SetStateAction<NotificationType>>;
  Notification: NotificationType;
  customerList: any;
  setCustomerList: any;
  playerList: any;
  setPlayerList: any;
  setIsPermissionOn: any;
  isPermissionOn: any;
  setCoachMonthStats: React.Dispatch<React.SetStateAction<coachmonthtstats>>;
  event: any;
  CoachMonthStats: coachmonthtstats;
  setEvent: any;
  setSelectedRegion: React.Dispatch<React.SetStateAction<any>>;
  selectedRegion: any;
  selectedCourt: any[];
  setselectedCourt: React.Dispatch<React.SetStateAction<any[]>>;
  selectedPlayers: any;
  servicePlayers: any;
  setServicePlayers: any;
  setSelectdPlayers: any;
  ratingDetails: rateDetails;
  setRatingDetails: React.Dispatch<React.SetStateAction<rateDetails>>;
  monthlyGraph: monthlyRate;
  setMonthlyGraph: React.Dispatch<React.SetStateAction<monthlyRate>>;
  weeklyGraph: weeklyRate;
  setWeeklyGraph: React.Dispatch<React.SetStateAction<weeklyRate>>;
  selCustomer: any;
  setSelCustomer: any;
  selPlayer: any;
  setSelPlayer: any;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  courts: { [key: string]: string[] };
  setCourts: React.Dispatch<React.SetStateAction<courtCoachType>>;
  coaches: { [key: string]: string[] };
  setCoaches: React.Dispatch<React.SetStateAction<courtCoachType>>;
  serviceList: any;
  setServiceList: React.Dispatch<React.SetStateAction<any>>;
  selectedDate: Date;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
  CancelledPlayer: any;
  setCancelledPlayer: any;
}

const MainContext = React.createContext<MainContextType>({} as MainContextType);

export default MainContext;
