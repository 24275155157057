import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { RxCross2 } from "react-icons/rx";
import MyDropdown from "./selectCheckBox";
import React, { useContext, useEffect, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { Controller, useForm } from "react-hook-form";
import {
  addLocationUser,
  deleteUser,
  getUser,
  updateUser,
} from "src/api/ClubAdmin";
import { dopdowncustomcss } from "src/constants/dropdownCSS";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogTitle, FormControl } from "@mui/material";
import MainContext from "src/context/MainContext";

const common = {
  display: "flex",
  alignItems: "center",
  fontFamily: "var(--font-regular)",
  color: "#22356D",
};

const inputStyle = {
  width: "160px",
  border: "1px solid #EEEEFE",
  padding: "3px 10px",
  fontFamily: "var(--font-regular)",
  color: "#22356DB2",
};

const style = {
  fontFamily: "var(--font-semibold)",
  boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
  padding: "7px 20px",
  borderRadius: "7px",
};

const ClubAdminPopUp = ({
  text,
  data,
  open,
  setOpen,
  updateLocation,
  updateLocationData,
  locationList,
}: {
  text: string;
  data: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  updateLocation: any;
  updateLocationData: any;
  locationList: any;
}) => {
  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();
  const { setNotification } = useContext(MainContext);
  const access = ["Sales stats", "Occupancy stats", "Customers stats"];
  const pagesAccess = ["Payments page", "Customers page", "Services page"];
  const { role } = watch();
  const { t } = useTranslation();
  const options = ["Edit", "View"];
  const [locations, setLocations] = useState<number[]>([]);
  const [selectAcc, setSelectAcc] = useState<any>([]);
  const [selectPageAcc, setSelectPageAcc] = useState<any>([]);
  const [option, setOption] = useState<any>([]);
  const [permission, setPermission] = useState([]);
  const [userData, setUserData] = useState<any>({});
  const [Validation, setValidation] = useState("");

  const handleOptionSelect = (option: number) => {
    const isSelected = locations.includes(option);

    if (isSelected) {
      setLocations((prevSelected: any) =>
        prevSelected.filter((item: any) => item !== option)
      );
    } else {
      setLocations((prevSelected: any) => [...prevSelected, option]);
    }
  };

  const addUser = async (data: any) => {
    let all_data;
    if (!data?.full_name) {
      setValidation("full_name");
      setTimeout(() => setValidation(""), 2000);
      return;
    } else if (!data?.email) {
      setValidation("email");
      setTimeout(() => setValidation(""), 2000);
      return;
    } else if (!role) {
      setValidation("role");
      setTimeout(() => setValidation(""), 2000);
      return;
    } else if (!data.password) {
      setValidation("password");
      setTimeout(() => setValidation(""), 2000);
      return;
    }

    if (role === "Staff") {
      all_data = {
        ...data,
        role: role.replace(" ", "_").toUpperCase(),
        locations: locations,
        capabilities_permissions: {
          view_permissions: option.includes("View"),
          edit_permissions: option.includes("Edit"),
        },
        dashboards_permissions: {
          sales: selectAcc.includes("Sales stats"),
          occupancy: selectAcc.includes("Occupancy stats"),
          customers: selectAcc.includes("Customers stats"),
        },
        pages_permissions: {
          customers_page: selectPageAcc.includes("Customers page"),
          services_page: selectPageAcc.includes("Services page"),
          payments_page: selectPageAcc.includes("Payments page"),
        },
      };

      try {
        const response = await addLocationUser(all_data);
        setNotification({
          isShow: true,
          type: "success",
          message: "Staff Admin created successfully",
          subMessage: "",
        });
        updateLocationData();
        setOpen(false);
      } catch (error: any) {
        setNotification({
          isShow: true,
          type: "error",
          message: error,
          subMessage: "",
        });
      }
    } else {
      all_data = {
        ...data,
        role: role.replace(" ", "_").toUpperCase(),
        locations: locations,
      };
      try {
        const response = await addLocationUser(all_data);
        updateLocationData();
        setOpen(false);
        setNotification({
          isShow: true,
          type: "success",
          message: "Staff Manager Admin created successfully",
          subMessage: "",
        });
      } catch (error: any) {
        setNotification({
          isShow: true,
          type: "error",
          message: error,
          subMessage: "",
        });
      }
    }
    reset();
    setSelectAcc([]);
    setSelectPageAcc([]);
    setPermission([]);
    setOption([]);
  };

  const updateUserData = async (data: any) => {
    let all_data;
    if (!data?.full_name) {
      setValidation("full_name");
      setTimeout(() => setValidation(""), 2000);
      return;
    } else if (!data?.email) {
      setValidation("email");
      setTimeout(() => setValidation(""), 2000);
      return;
    } else if (!role) {
      setValidation("role");
      setTimeout(() => setValidation(""), 2000);
      return;
    }

    if (role == "Staff") {
      all_data = {
        ...data,
        locations: locations,
        role: role.replace(" ", "_").toUpperCase(),
        capabilities_permissions: {
          view_permissions: option.includes("View"),
          edit_permissions: option.includes("Edit"),
        },
        dashboards_permissions: {
          sales: selectAcc.includes("Sales stats"),
          occupancy: selectAcc.includes("Occupancy stats"),
          customers: selectAcc.includes("Customers stats"),
        },
        pages_permissions: {
          customers_page: selectPageAcc.includes("Customers page"),
          services_page: selectPageAcc.includes("Services page"),
          payments_page: selectPageAcc.includes("Payments page"),
        },
      };

      try {
        const response = await updateUser(userData?.id, all_data);
        setNotification({
          isShow: true,
          type: "success",
          message: "Staff Admin updated successfully",
          subMessage: "",
        });
        updateLocationData();
        setOpen(false);
      } catch (error: any) {
        setNotification({
          isShow: true,
          type: "error",
          message: error,
          subMessage: "",
        });
      }
    } else {
      all_data = {
        ...data,
        role: role.replace(" ", "_").toUpperCase(),
        locations: locations,
      };
      try {
        const response = await updateUser(userData?.id, all_data);
        updateLocationData();
        setOpen(false);
        setNotification({
          isShow: true,
          type: "success",
          message: "Staff Manager Admin updated successfully",
          subMessage: "",
        });
      } catch (error: any) {
        setNotification({
          isShow: true,
          type: "error",
          message: error,
          subMessage: "",
        });
      }
    }

    reset();
    setSelectAcc([]);
    setSelectPageAcc([]);
    setPermission([]);
    setOption([]);
  };

  const handleClick = async () => {
    if (userData && Object.keys(userData).length !== 0) {
      const response = await deleteUser(userData.id);
      if (response) {
        updateLocationData();
        setOpen(false);
      }
    } else {
      setOpen(false);
      console.log("can't delete");
    }
  };

  const getUserDatails = async (id: number) => {
    if (id) {
      try {
        const data = await getUser(id);
        setUserData(data.data);
        let userData = data.data;
        reset({
          full_name: userData?.full_name,
          email: userData?.email,
          // phone_number: userData?.phone_number,
          role: userData?.role?.name
            .replace("_", " ")
            .toLowerCase()
            .replace(/\b\w/g, (c: any) => c.toUpperCase()),
          locations: userData?.locations,
        });
        const filterData = userData?.locations?.map((item: string) => {
          const location = locationList.find(
            (l: any) => l.location_name === item
          );
          return location ? location.id : null;
        });
        setLocations(filterData);
        const otherPermissions = userData?.otherPermissions;
        const updatedPEr: any = [];
        const updatedPageAccess: any = [];
        otherPermissions?.occupancy && updatedPEr.push("Occupancy stats");
        otherPermissions?.sales && updatedPEr.push("Sales stats");
        otherPermissions?.customers && updatedPEr.push("Customers stats");
        otherPermissions?.payments_page &&
          updatedPageAccess.push("Payments page");
        otherPermissions?.customers_page &&
          updatedPageAccess.push("Customers page");
        otherPermissions?.services_page &&
          updatedPageAccess.push("Services page");
        setSelectAcc(updatedPEr);
        setSelectPageAcc(updatedPageAccess);
        const selectededd: any = [];
        otherPermissions.view_permissions && selectededd.push("View");
        otherPermissions.edit_permissions && selectededd.push("Edit");
        setOption(selectededd);
      } catch (error) {
        console.log("error in fetching regions");
      }
    }
  };

  useEffect(() => {
    if (!!Object.keys(data || {})?.length) {
      getUserDatails(data?.user_id);
    } else {
      reset({
        full_name: "",
        email: "",
        role: "",
        locations: [],
      });
      if (text === "STAFF") {
        setSelectAcc(["customers"]);
      }

      setOption([]);
      setLocations([]);
    }
  }, [data]);

  useEffect(() => {
    setValue(
      "role",
      text
        .replace("_", " ")
        .toLowerCase()
        .replace(/\b\w/g, (c) => c.toUpperCase())
    );
  }, [text]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "5px 10px",
        }}
      >
        <RxCross2
          onClick={() => setOpen(false)}
          style={{ fontSize: "15px", cursor: "pointer" }}
        />
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={
            data && Object.keys(data).length !== 0
              ? handleSubmit(updateUserData)
              : handleSubmit(addUser)
          }
        >
          <Typography
            sx={{ fontFamily: "var(--font-medium)", color: "#22356D" }}
          >
            {t("locationmanager.Admin Info")}
          </Typography>
          <Box
            sx={{
              boxShadow: "4px 4px 4px 0px #0000000D inset",
              background: "#889AB61A",
              padding: { xs: "10px", sm: "10px 20px" },
              borderRadius: "7px",
              width: { xs: "auto", sm: "500px" },
            }}
          >
            <Box
              sx={{
                ...common,
                justifyContent: "space-between",
                marginBottom: { xs: "5px", sm: "10px" },
                gap: { xs: "35px", sm: "0px" },
                alignItems: { xs: "flex-start", sm: "center" },
              }}
            >
              <Box>
                <input
                  id={Validation === "full_name" ? "customerror" : ""}
                  type="text"
                  placeholder={t("common.Enter Name")}
                  {...register("full_name")}
                  style={{ ...inputStyle }}
                />
              </Box>
              <Box
                sx={{
                  background: "#889AB626",
                  padding: "0px 8px",
                  borderRadius: "7px",
                  fontSize: { xs: "0.8rem", sm: "1rem" },
                }}
              >
                {role}
              </Box>
            </Box>
            <Box
              sx={{
                ...common,
                justifyContent: "space-between",
                marginBottom: "10px",
                display: { xs: "grid", sm: "flex" },
                gap: { xs: "5px", sm: "0px" },
              }}
            >
              {/* <input type="text" placeholder={t('common.Number')} {...register('phone_number', {required: true})} style={{...inputStyle}} /> */}
              <Box>
                <input
                  id={Validation === "email" ? "customerror" : ""}
                  type="text"
                  placeholder={t("locationmanager.Enter Email")}
                  {...register("email")}
                  style={{ ...inputStyle }}
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography
              sx={{
                fontFamily: "var(--font-medium)",
                marginBlock: "20px",
                borderBottom: "2px solid #22356D",
                width: "fit-content",
              }}
            >
              {t("locationmanager.Admin Settings")}
            </Typography>
            <Box
              sx={{
                fontFamily: "var(--font-regular)",
                display: "flex",
                flexDirection: "column",
                gap: "7px",
              }}
            >
              <Box
                sx={{
                  ...common,
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <p>{t("locationmanager.Admin Role")}</p>
                <Box>
                  <FormControl>
                    <Select
                      MenuProps={{ sx: dopdowncustomcss }}
                      renderValue={(value) => {
                        if (value !== "") {
                          return `${value}`;
                        }
                        return <em>Placeholder</em>;
                      }}
                      value={!!role ? role : "Select Role"}
                      id="customdropdown"
                      style={{
                        width: "160px",
                        height: "27px",
                        border: "1px solid #EEEEFE",
                        borderRadius: "7px",
                        fontSize: "13px",
                        fontFamily: "var(--font-regular) !important",
                        color: "#22356D",
                      }}
                      IconComponent={BsChevronDown}
                    >
                      {["Staff Manager", "Staff"].map((role: string) => (
                        <MenuItem
                          onClick={() => setValue("role", role)}
                          style={{
                            fontSize: "14px",
                            opacity: "0.7",
                            fontFamily: "var(--font-regular) !important",
                            color: "#22356D",
                          }}
                          key={role}
                          value={role}
                        >
                          {role}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                {/* <MyDropdown arr={["Staff Manager","Staff"]} text={"role"} selected={selectAcc} setSelected={(role:any)=>setValue('role',role)} /> */}
              </Box>
              {/* {text==="STAFF" && <Box sx={{...common, justifyContent:'space-between',marginBottom:"10px"}}>
                    <p>Edit Capabilties</p>
                    <MyDropdown arr={options} text={"Capabilties"} selected={option} setSelected={setOption} />
                </Box>} */}
              {role === "Staff" && (
                <Box
                  sx={{
                    ...common,
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <p>{t("locationmanager.Staff Permission")}</p>
                  <MyDropdown
                    arr={options}
                    text={"Permission"}
                    selected={option}
                    setSelected={setOption}
                  />
                </Box>
              )}
              {role === "Staff" && (
                <Box
                  sx={{
                    ...common,
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <p>{t("locationmanager.Access to Club Stats Dashboards")}</p>
                  <MyDropdown
                    arr={access}
                    text={"Access"}
                    selected={selectAcc}
                    setSelected={setSelectAcc}
                  />
                </Box>
              )}
              {role === "Staff" && (
                <Box
                  sx={{
                    ...common,
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <p>{t("common.Access to Pages")}</p>
                  <MyDropdown
                    arr={pagesAccess}
                    text={"Access"}
                    selected={selectPageAcc}
                    setSelected={setSelectPageAcc}
                  />
                </Box>
              )}
              <Box
                sx={{
                  ...common,
                  justifyContent: "space-between",
                  marginBottom: "10px",
                  position: "relative",
                }}
              >
                <p>{t("locationmanager.Location Access")}</p>
                <Box position={"relative"} width={"160px"}>
                  <Controller
                    name="locations"
                    control={control}
                    defaultValue={[]} // set initial value to empty array
                    render={({ field }) => (
                      <Select
                        MenuProps={{ sx: dopdowncustomcss }}
                        {...field}
                        multiple
                        style={{
                          width: "160px",
                          height: "27px",
                          border: "1px solid #EEEEFE",
                          borderRadius: "7px",
                          fontFamily: "var(--font-regular) !important",
                          color: "#22356D",
                        }}
                        IconComponent={BsChevronDown}
                        label="Select locations"
                        displayEmpty
                        renderValue={(selected) => {
                          return selected.length === 0 ? (
                            <p
                              style={{
                                fontFamily: "var(--font-regular)",
                                color: "#22356DB2",
                              }}
                            >
                              {t("locationmanager.Select locations")}
                            </p>
                          ) : (
                            <p style={{ fontFamily: "var(--font-regular)" }}>
                              {selected?.length > 1
                                ? `${selected[0]} + ${selected.length - 1}`
                                : selected.join(",")}
                            </p>
                          );
                        }}
                      >
                        {locationList.map((location: any) => (
                          <MenuItem
                            key={location.id}
                            value={location.location_name}
                            onClick={() => handleOptionSelect(location.id)}
                          >
                            {location.location_name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  ...common,
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <p>{t("customers.New Password")}</p>
                <Box>
                  <input
                    id={Validation === "password" ? "customerror" : ""}
                    type="password"
                    placeholder={
                      Object.keys(data || {})?.length !== 0
                        ? t("common.Enter new password")
                        : t("common.Enter password")
                    }
                    {...register("password")}
                    style={{ ...inputStyle }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"} marginTop={2}>
            <button
              style={{ ...style, color: "#22356D" }}
              type="button"
              onClick={handleClick}
            >
              {Object.keys(userData || {}).length !== 0
                ? t("common.Delete")
                : t("common.Cancel")}
            </button>
            <button
              style={{ ...style, color: "white", background: "#22356D" }}
              type="submit"
            >
              {t("common.Save")}
            </button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ClubAdminPopUp;
