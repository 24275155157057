import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MainContext from "./context/MainContext";
import { RouterProvider } from "react-router-dom";
import router from "./router/Routes";
import { useTranslation } from "react-i18next";
import ChatSupport from "./pages/ChatSuport";

const App = () => {
  const context = useContext(MainContext);
  const { i18n } = useTranslation();

  const authenticated = context?.authenticated;
  const setAuthenticated = context?.setAuthenticated;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      if (setAuthenticated) {
        setAuthenticated(true);
      }
    }
    setLoading(false);
  }, [authenticated, setAuthenticated]);

  useEffect(() => {
    const handleOrientationChange = () => {
      window.location.reload();
    };

    // Add event listener for orientation change
    window.addEventListener("orientationchange", handleOrientationChange);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  const Lang: number = Number(window.localStorage.getItem("Lng")) || 1;
  useEffect(() => {
    const loclang = Lang == 1 ? "en" : Lang == 2 ? "es" : "ru";
    i18n.changeLanguage(loclang);
  }, [Lang]);

  if (loading) {
    return <div>Loading..</div>;
  }

  return (
    <Grid container>
      <RouterProvider router={router} />
      <ChatSupport />
    </Grid>
  );
};

export default App;
