import debounce from "lodash.debounce";
import { apiCaller } from "./ApiCaller";
import { useCallback, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import MainContext from "src/context/MainContext";

export const getClubId = () => {
  const user_data = JSON.parse(
    localStorage.getItem("user") || JSON.stringify({})
  );

  if (user_data?.role?.name === "SUPER_ADMIN") {
    const club_id = JSON.parse(
      localStorage.getItem("Club_id") || JSON.stringify({})
    );
    return club_id;
  } else {
    let club_id = user_data?.club_id;
    return club_id;
  }
};

export const useGetClubId = () => {
  const { LoginData } = useContext(MainContext);
  const getClubId = () => {
    if (LoginData?.role?.name === "SUPER_ADMIN") {
      const club_id = JSON.parse(
        localStorage.getItem("Club_id") || JSON.stringify({})
      );
      return club_id;
    } else {
      let club_id = LoginData?.club?.id;
      return club_id;
    }
  };

  const club_id = getClubId();
  return club_id;
};

export const useGetPermissions = () => {
  const { LoginData } = useContext(MainContext);
  const LocalPerm: any = localStorage.getItem("permission");
  const LocalPermission: any = JSON.parse(LocalPerm);
  if (LoginData?.role?.name === "STAFF") {
    const permission =
      LoginData?.otherPermissions || LocalPermission?.otherPermissions;
    return permission;
  } else {
    return { edit_permissions: true, role: "OTHER" };
  }
};

export const Permission = () => {
  const user_data = JSON.parse(
    localStorage.getItem("user") || JSON.stringify({})
  );
  if (user_data?.role.name === "STAFF") {
    const permission = JSON.parse(
      localStorage.getItem("permission") || JSON.stringify({})
    );
    return permission?.otherPermissions;
  } else {
    return { edit_permissions: true, role: "OTHER" };
  }
};

export const useGetMyDetails = () => {
  let club_id = getClubId();

  const query = useQuery(
    ["GetMyDetails"],
    async () => {
      const response: any = await apiCaller.get(`${club_id}/admins/user/getme`);
      let res = response?.data;
      return res;
    },
    {
      staleTime: 1000 * 3,
      retry: false,
      enabled: true,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

//get details
export const useGetBookingsEvents = () => {
  let club_id = getClubId();
  const [sport_id, setsport_id] = useState<number>();
  const [viewType, setviewType] = useState<string>();

  const query = useQuery(
    ["GetBookingsEvents", sport_id, viewType],
    async () => {
      const response: any = await apiCaller.get(
        `${club_id}/calendars/${viewType}/${sport_id}`
      );
      return response.data.data;
    },
    {
      staleTime: 1000 * 3,
      retry: false,
      enabled: !!sport_id && !!viewType,
      refetchOnWindowFocus: false,
    }
  );

  return { setviewType, setsport_id, ...query };
};

export const useGetAllServices = () => {
  let club_id = getClubId();
  const [location_id, setLocation_id] = useState<number>();
  const [date, setDate] = useState<string>();

  const query = useQuery(
    ["calendar-daily-date", location_id, date],
    async () => {
      const response: any = await apiCaller.get(
        `${club_id}/calendars/services/${location_id}/${date}`
      );
      return response.data?.data;
    },
    {
      enabled: !!location_id && !!date,
      staleTime: 1000 * 60 * 5,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
  return { setDate, setLocation_id, ...query };
};

// get all stats
export const useGetAllStats = () => {
  let club_id = getClubId();
  const [location_id, setLocation_id] = useState<number>();
  const [sports, setSports] = useState([]);
  const [date, setDate] = useState<string>();

  const queryString = sports.map((id) => `sportId=${id}`).join("&");

  const query = useQuery(
    ["calendar-daily-stats", location_id, date, sports],
    async () => {
      const response: any = await apiCaller.get(
        `${club_id}/calendars/services/stats/${location_id}/${date}?${queryString}`
      );
      return response.data?.data;
    },
    {
      enabled: !!location_id && !!date,
      staleTime: 1000 * 60 * 5,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
  return { setDate, setLocation_id, sports, setSports, ...query };
};

// get booking details from service booking id
export const useGetParticularBooking = () => {
  let club_id = getClubId();
  const [serviceId, setServiceId] = useState<number>();

  const query = useQuery(
    ["calendar-daily-stats", serviceId],
    async () => {
      const response: any = await apiCaller.get(
        `${club_id}/calendars/services/${serviceId}`
      );
      return response.data?.data;
    },
    {
      enabled: !!serviceId,
      staleTime: 1000 * 60,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
  return { setServiceId, ...query };
};

// get all logs
const getLogs = async (service_id: number) => {
  try {
    let club_id = getClubId();
    let res = await apiCaller.get(
      `${club_id}/calendars/services/view-logs/${service_id}`
    );
    return res;
  } catch (error: any) {
    let err = error.response?.data?.message;
    console.log(err);
    return err;
  }
};

// get all logs
export const GetServiceLogs = async (service_id: number) => {
  try {
    let club_id = getClubId();
    let res = await apiCaller.get(`${club_id}/services/logs/${service_id}`);
    return res;
  } catch (error: any) {
    let err = error.response?.data?.message;
    console.log(err);
    return err;
  }
};

// get service by the id
const getServiceById = async (service_id: number) => {
  try {
    let club_id = getClubId();
    let response: any = await apiCaller.get(
      `${club_id}/calendars/services/${service_id}`
    );
    return response;
  } catch (error: any) {
    const err = error.response?.data?.message;
    throw err;
  }
};

// get pending payments by service booking id
export const getPendingPaymentsById = async (service_id: number) => {
  try {
    let club_id = getClubId();
    let response: any = await apiCaller.get(
      `${club_id}/payments/services/pending-payments/${service_id}`
    );
    return response?.data?.data;
  } catch (error: any) {
    const err = error.response?.data?.message;
    throw err;
  }
};

// create service
const createBooking = async (data: any) => {
  try {
    let club_id = getClubId();
    let response: any = await apiCaller.post(
      `${club_id}/calendars/services`,
      data
    );
    return response;
  } catch (error: any) {
    const err = error.response?.data?.message;
    throw err;
  }
};

// update service
const updateBookingData = async (
  service_id: number,
  service_booking_id: number,
  data: any
) => {
  try {
    let club_id = getClubId();
    let res: any = await apiCaller.put(
      `${club_id}/calendars/servics/${service_id}`,
      data
    );
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// delete conflict service
const deleteSchService = async (service_booking_id: number) => {
  try {
    let club_id = getClubId();
    let res: any = await apiCaller.delete(
      `${club_id}/calendars/schedule-bookings/${service_booking_id}`
    );
    console.log(res);
    return res;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// get all customer list
export const useGetAllCustomerList = () => {
  const club_id = getClubId();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [debouncedName, setDebouncedName] = useState<string>("");

  const debouncedSetName = useCallback(
    debounce((value: string) => setDebouncedName(value), 500), // 500ms delay
    []
  );

  useEffect(() => {
    debouncedSetName(searchQuery);
  }, [searchQuery]);

  const query = useQuery(
    ["CustomersList", debouncedName],
    async () => {
      const response = await apiCaller.get(
        `${club_id}/services/customers?search=${debouncedName}`
      );
      return response.data.data;
    },
    {
      enabled: true,
      staleTime: 1000 * 60 * 5,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  return { setSearchQuery, ...query };
};

// get player score by service booking id
export const useGetScore = () => {
  const club_id = getClubId();
  const [Service_id, setService_id] = useState<number>();

  const query = useQuery(
    ["getScore", Service_id],
    async () => {
      const response = await apiCaller.get(
        `${club_id}/players/team-scores/${Service_id}`
      );
      return response.data.data;
    },
    {
      enabled: !!Service_id,
      staleTime: 1000 * 60 * 5,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
  return { ...query, setService_id };
};

// update player score by service booking id
export const updateScore = async (Service_id: number, apiData: any) => {
  const club_id = getClubId();
  const response = await apiCaller.put(
    `${club_id}/players/team-scores/${Service_id}`,
    apiData
  );
  return response;
};

// update waiting approve / remove
export const updateWaiting = async (
  player_id: number,
  Service_id: number,
  type: string
) => {
  const club_id = getClubId();
  const response = await apiCaller.patch(
    `${club_id}/players/requests/${player_id}/${Service_id}/${type}`
  );
  return response;
};

//move to player from waiting list
export const updateMoveWaiting = async (
  waiting_id: any,
  Service_id: number
) => {
  const club_id = getClubId();
  const response = await apiCaller.patch(
    `${club_id}/players/move-player/${Service_id}/${waiting_id}`,
    { action: "MOVE_TO_LIST" }
  );
  return response;
};

// get all waiting approval list by service booking id
export const useGetAllWaitingApprovalList = () => {
  const club_id = getClubId();
  const [Service_id, setService_id] = useState<number>();

  const query = useQuery(
    ["waitingapprovalList", Service_id],
    async () => {
      const response = await apiCaller.get(
        `${club_id}/players/waiting-lists/${Service_id}`
      );
      return response.data.data;
    },
    {
      enabled: !!Service_id,
      staleTime: 1000 * 60 * 5,
      retry: false,
    }
  );
  return { setService_id, ...query };
};

// get all player notes
export const useGetPlayersNotes = () => {
  const club_id = getClubId();
  const [Service_id, setService_id] = useState<number>();

  const query = useQuery(
    ["GetPlayerNotes", Service_id],
    async () => {
      const response = await apiCaller.get(
        `${club_id}/calendars/services/view/${Service_id}`
      );
      return response.data.data;
    },
    {
      enabled: !!Service_id,
      staleTime: 1000 * 60 * 5,
      retry: false,
    }
  );
  return { setService_id, ...query };
};

// add player in waiting list
const UpdateServicesPlayers = async (
  playerId: number,
  type: string,
  isSend: boolean
) => {
  try {
    let club_id = getClubId();
    let res: any = await apiCaller.patch(
      `${club_id}/calendars/services/players/${playerId}?type=${type}&is_send=${isSend}`
    );
    return res.data.data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// hide service from the calendars page
export const HideService = async (service_id: number, apidata: any) => {
  try {
    let club_id = getClubId();
    let res: any = await apiCaller.patch(
      `${club_id}/calendars/services/hide/${service_id}`,
      apidata
    );
    return res.data.data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// get all players list if any
const getPlayerList = async (service_id: number) => {
  try {
    let club_id = getClubId();
    let res = await apiCaller.get(
      `${club_id}/calendars/services/players/list/${service_id}`
    );
    return res;
  } catch (error: any) {
    let err = error.response?.data?.message;
    return err;
  }
};

// get all players list
export const useGetAllPlayerList = () => {
  const club_id = getClubId();
  const [service_id, setService_id] = useState<number>();

  const query = useQuery(
    ["getallDoubleplayer-list", service_id],
    async () => {
      const response = await apiCaller.get(
        `${club_id}/calendars/services/players/list/${service_id}`
      );
      return response.data.data;
    },
    {
      enabled: !!service_id,
      staleTime: 1000 * 60 * 5,
      retry: false,
    }
  );
  return { setService_id, ...query };
};

// get all waiting list by service booking id
export const useGetAllWaitingList = () => {
  const club_id = getClubId();
  const [service_id, setService_id] = useState<number>();

  const query = useQuery(
    ["getallwaiting-list", service_id],
    async () => {
      const response = await apiCaller.get(
        `${club_id}/players/waiting-lists/${service_id}`
      );
      return response.data.data;
    },
    {
      enabled: !!service_id,
      staleTime: 1000 * 60 * 5,
      retry: false,
    }
  );
  return { setService_id, ...query };
};

// add waiting list for team
const addWaitingTeam = async (service_id: number, data: any) => {
  try {
    let club_id = getClubId();
    let res = await apiCaller.post(
      `${club_id}/players/waiting-lists/${service_id}`,
      data
    );
    return res;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// delete the service
const deleteService = async (service_id: number, apiData: any) => {
  try {
    let club_id = getClubId();
    let res: any = await apiCaller.patch(
      `${club_id}/calendars/cancel-services`,
      apiData
    );

    console.log(res);
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// add internal note
const addNotes = async (serviceId: number, data: { notes: string }) => {
  try {
    let club_id = getClubId();
    let res = await apiCaller.post(
      `${club_id}/services/notes/${serviceId}`,
      data
    );
    return res;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// Payments share logic screen
export const UpdatePayments = async (serviceId: number, data: any) => {
  try {
    let club_id = getClubId();
    let res = await apiCaller.post(
      `${club_id}/payments/services/${serviceId}`,
      data
    );
    return res;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

const transformData = (data: any) => {
  const transformedData: any = {};
  data.forEach((item: any) => {
    const courtId = item.court_id;
    if (!transformedData[courtId]) {
      transformedData[courtId] = {
        court_name: item.court_name,
        court_id: courtId,
        sport: item.sport,
        occupancy: [],
      };
    }
    transformedData[courtId].occupancy.push(item);
  });
  const result = Object.values(transformedData);
  return result;
};

const getAllRecurringServices = async (booking_id: number) => {
  let club_id = getClubId();
  try {
    const response = await apiCaller.get(
      `${club_id}/calendars/get-service-booking/${booking_id}`
    );
    return response.data.data;
  } catch (error) {
    console.log(error, "eroror");
  }
};

// get all service of a month
const getMonthsService = async (
  view_type: string,
  month_year: any,
  location_id: number
) => {
  try {
    let club_id = getClubId();
    let res = await apiCaller.get(
      `${club_id}/calendars/occupancy-rate/${location_id}/${view_type}/${month_year}`
    );
    let data = res?.data?.data;
    if (view_type === "courts") {
      let setData = transformData(data);
      return setData;
    }
    return res;
  } catch (error: any) {
    let err = error.response?.data?.message;
    console.log(err);
    return err;
  }
};

export {
  getLogs,
  getAllRecurringServices,
  createBooking,
  getMonthsService,
  getServiceById,
  updateBookingData,
  deleteSchService,
  getPlayerList,
  addWaitingTeam,
  UpdateServicesPlayers,
  deleteService,
  addNotes,
};
