// MyProvider.js
import React, { ReactNode, useState } from "react";
import MainContext, {
  MainContextType,
  NotificationType,
  coachmonthtstats,
  courtCoachType,
  courtMonthlyStats,
  locationTypes,
  monthlyRate,
  rateDetails,
  weeklyRate,
} from "./MainContext";
import moment from "moment";

// Define the type for the props of MainProvider
interface MainProviderProps {
  children: ReactNode;
}

interface Role {
  name: string;
}

interface Club {
  id: number;
  club_name: string;
}

interface OtherPermissions {
  view_permissions: boolean;
  edit_permissions: boolean;
  sales: boolean;
  occupancy: boolean;
  customers: boolean;
}

interface LocationPermission {
  location_id: number;
}

interface User {
  id: number;
  full_name: string;
  email: string;
  address: string | null;
  role: Role;
  club: Club;
  OtherPermissions: OtherPermissions;
  LocationPermissions: LocationPermission[];
}

const permissionObj = {
  edit_permissions: false,
  occupancy: false,
  sales: false,
  view_permissions: false,
  customer: false,
};

const MainProvider: React.FC<MainProviderProps> = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [LoginData, setLoginData] = useState<User>();
  const [Notification, setNotification] = useState<NotificationType>({
    message: "",
    type: "",
    subMessage: "",
    isShow: false,
  });
  const [UpdatePayments, setUpdatePayments] = useState<boolean>(false);
  const [selectedRegion, setSelectedRegion] = useState<any>();
  const [courtMonthlyStats, setCourtMonthlystats] = useState<courtMonthlyStats>(
    { occupiedHours: 0, occupancyRate: 0 }
  );
  const [selectedLocationId, setSelectedLocationId] = useState<number>(0);
  const [sport_id, setSport_id] = useState([]);
  const [sideBar, setSideBar] = useState(false);
  const [MobileSideBar, setMobileSideBar] = useState(false);
  const [ActiveTab, setActiveTab] = useState("");
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const [selectedMonth, setSelectedMonth] = useState<any>(moment());
  const [resServicesList, setServicesList] = useState([]);
  const [CancelledPlayer, setCancelledPlayer] = useState<any[]>([]);
  const [isPermissionOn, setIsPermissionOn] = useState<boolean>(false);
  const [SelectedLocation, setSelectedLocation] = useState<locationTypes>({
    id: 0,
    location_name: "all location",
    currency: "IDR",
  });
  const [CoachMonthStats, setCoachMonthStats] = useState<coachmonthtstats>({
    occupancyRate: 0,
    overallTotalEventsHours: 0,
    eventPercent: 0,
    overallTotalLessonsHours: 0,
    lessonsPercent: 0,
  });
  const [customerList, setCustomerList] = useState([]);
  const [playerList, setPlayerList] = useState([]);
  const [selCustomer, setSelCustomer] = useState({});
  const [selPlayer, setSelPlayer] = useState({});
  const [SelectedCourtDurations, setSelectedCourtDurations] = useState<any[]>(
    []
  );

  const [selectedCustomerid, setSelectedCustomerId] = useState<any>();
  const [serviceList, setServiceList] = useState<any>([]);
  const [ratingDetails, setRatingDetails] = useState<rateDetails>({
    bookingPercent: 0,
    eventPercent: 0,
    lessonPercent: 0,
    noShowCounts: 0,
    openMatchCounts: 0,
    pendingPayments: 0,
    unfilledOpenMatchCounts: 0,
    dailyOccupancyRate: "0",
    dailyOccupancyHours: 0,
  });
  const [monthlyGraph, setMonthlyGraph] = useState<monthlyRate>({
    bookingPercentage: 0,
    lessonPercentage: 0,
    eventPercentage: 0,
    openMatchCounts: 0,
    totalPendingPayments: 0,
  });
  const [weeklyGraph, setWeeklyGraph] = useState<weeklyRate>({
    coachesDetails: [],
    noShowCounts: 0,
    pendingPayments: 0,
  });
  const [event, setEvent] = useState({});
  const [selectedPlayers, setSelectdPlayers] = useState<any>([]);
  const [courts, setCourts] = useState<courtCoachType>({
    Pererene: ["Court 1"],
  });
  const [selectedCourt, setselectedCourt] = useState<any[]>([]);
  const [coaches, setCoaches] = useState<courtCoachType>({
    Pererene: ["tash"],
  });
  const [chatURL, setChatURL] = useState<string>("");
  const [servicePlayers, setServicePlayers] = useState<any[]>([]);

  const contextValue: MainContextType = {
    selectedLocationId,
    setSelectedLocationId,
    setCoachMonthStats,
    CoachMonthStats,
    setSelectedLocation,
    SelectedLocation,
    UpdatePayments,
    setNotification,
    selectedCourt,
    setselectedCourt,
    Notification,
    setCourtMonthlystats,
    courtMonthlyStats,
    setUpdatePayments,
    authenticated,
    setAuthenticated,
    selectedCustomerid,
    setIsPermissionOn,
    isPermissionOn,
    chatURL,
    setChatURL,
    setSelectedCustomerId,
    resServicesList,
    setServicesList,
    customerList,
    setLoginData,
    LoginData,
    setCustomerList,
    setMobileSideBar,
    MobileSideBar,
    playerList,
    setPlayerList,
    selCustomer,
    setSelCustomer,
    setSelectedCourtDurations,
    SelectedCourtDurations,
    selPlayer,
    setSelPlayer,
    setSelectedRegion,
    selectedRegion,
    CancelledPlayer,
    setCancelledPlayer,
    event,
    setEvent,
    servicePlayers,
    setServicePlayers,
    selectedPlayers,
    setSelectdPlayers,
    ActiveTab,
    setActiveTab,
    selectedMonth,
    setSelectedMonth,
    ratingDetails,
    setRatingDetails,
    monthlyGraph,
    setMonthlyGraph,
    weeklyGraph,
    setWeeklyGraph,
    courts,
    setCourts,
    coaches,
    setCoaches,
    serviceList,
    setServiceList,
    selectedDate,
    setSelectedDate,
    sideBar,
    setSideBar,
    sport_id,
    setSport_id,
  };

  return (
    <MainContext.Provider value={contextValue}>{children}</MainContext.Provider>
  );
};

export default MainProvider;
