import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CustomModel from "../CustomModel";
import React, { useContext, useEffect, useState } from "react";
import { SelectRegionBox } from "../SelectBox";
import LocationOwner from "src/features/Admin/LocationOwner";
import { useForm } from "react-hook-form";
import {
  createLocation,
  deleteLocation,
  updateLocationByID,
  useGetAllStaff,
  useGetRegions,
} from "src/api/ClubAdmin";
import EditPaymentType from "./EditPaymentType";
import { BsChevronDown } from "react-icons/bs";
import { useGetAllPaymentMethodsByLoc } from "src/hooks/useGetAllPaymentMethods";
import CurrencySelector from "./CurrencySelector";
import { useTranslation } from "react-i18next";
import MainContext from "src/context/MainContext";

const common = {
  display: "flex",
  alignItems: "center",
  fontFamily: "var(--font-regular)",
  color: "#22356D",
};

const textBetween = {
  justifyContent: "space-between",
  gap: { xs: "0", sm: "6rem" },
};

const style = {
  fontFamily: "var(--font-semibold)",
  boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
  padding: "7px 20px",
  borderRadius: "7px",
};

interface region {
  id: number;
  club_id: number;
  sequence: number;
  region_name: string;
}

const LocationAddModel = ({
  text,
  data,
  open,
  setOpen,
  setAddUser,
  adminPop,
  updateLocation,
  updateLocationData,
  setData,
}: {
  text: string;
  data: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  setAddUser: (addUser: string) => void;
  adminPop: (openPop: boolean) => void;
  updateLocation: any;
  updateLocationData: any;
  setData: any;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<any>({
    defaultValues: {
      merchant_id: "",
    },
  });
  const [isMerchantId, setIsMerchantId] = useState(false);
  const { setNotification } = useContext(MainContext);
  const [AllStaff, SetAllStaff] = useState([]);
  const { t } = useTranslation();
  const [region, setRegion] = useState<any>("");
  const [currency, setCurrency] = useState("");
  const [value, setValues] = React.useState("1");
  const [showModel, setShowModel] = useState(false);
  const [regionsData, setRegionsData] = React.useState([]);
  const [Paymentdata, setPaymentData] = useState<any[]>([]);
  const {
    data: StaffData,
    isSuccess: StaffSuccess,
    isError: StaffError,
  } = useGetAllStaff();
  const {
    data: AllRegions,
    isSuccess: RegionsSuccess,
    isError: RegionsError,
    refetch: RegionsRefetch,
  } = useGetRegions();
  const {
    data: PaymentMethods,
    isSuccess,
    isError,
    refetch,
    setlocation_id,
    setIsPayment,
  } = useGetAllPaymentMethodsByLoc();

  const handleSelectTab = (event: React.SyntheticEvent, newValue: string) => {
    setValues(newValue);
  };

  const handleClick = () => {
    if (Object.keys(data).length > 0) {
      deleteLocation(data.id);

      setTimeout(() => {
        updateLocation();
        updateLocationData();
      }, 1200);
    }
    setOpen(false);
  };

  const createLocationReg = async (item: any) => {
    let all_data = {
      ...item,
      currency: currency,
      region_id: region.id,
    };

    if (Object.keys(data).length > 0) {
      try {
        const response: any = await updateLocationByID(data.id, all_data);
        updateLocation();
        setOpen(false);
        setNotification({
          isShow: true,
          type: "success",
          message: response.message,
          subMessage: "",
        });
        updateLocationData();
        reset();
      } catch (error: any) {
        setNotification({
          isShow: true,
          type: "errror",
          message: error,
          subMessage: "",
        });
      }
    } else {
      try {
        const response: any = await createLocation(all_data);
        updateLocation();
        setNotification({
          isShow: true,
          type: "success",
          message: response.message,
          subMessage: "",
        });
        updateLocationData();
        setOpen(false);
        reset();
      } catch (error: any) {
        setNotification({
          isShow: true,
          type: "error",
          message: error,
          subMessage: "",
        });
      }
    }
  };

  const filterUsersByRole = (userData: any, roleName: string) => {
    return userData?.filter((user: any) => user.user?.role.name === roleName);
  };

  useEffect(() => {
    setlocation_id(data?.id);
    setIsPayment(true);
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      const sorted = PaymentMethods?.sort(
        (a: any, b: any) => a.sequence - b.sequence
      );
      if (sorted) {
        setPaymentData(sorted);
      } else {
        setPaymentData([]);
      }
    } else {
      setPaymentData([]);
    }
  }, [PaymentMethods, isSuccess, isError, showModel]);

  useEffect(() => {
    if (RegionsSuccess) {
      setRegionsData(AllRegions);
    } else {
      setRegionsData([]);
    }
  }, [AllRegions, RegionsError, RegionsSuccess]);

  //get all Staff
  useEffect(() => {
    if (StaffSuccess) {
      SetAllStaff(StaffData);
    } else {
      SetAllStaff([]);
    }
  }, [StaffData, StaffSuccess, StaffError]);

  useEffect(() => {
    if (data) {
      reset({
        location_name: data.location_name,
        georeferenced: data.georeferenced,
        merchant_id: data.merchant_id !== null ? data.merchant_id : "",
      });
      setCurrency(data.currency);
      setRegion(data.region);
      if (data.merchant_id && data.merchant_id !== null) {
        setIsMerchantId(true);
      }
    } else {
      reset();
      setCurrency("");
      setRegion("");
    }
  }, [data]);

  const paymentsda = !!Paymentdata?.length
    ? Paymentdata?.length > 1
      ? `${Paymentdata[0]?.method_name} + ${Paymentdata?.length}`
      : Paymentdata[0]?.method_name
    : "";

  return (
    <CustomModel
      open={open}
      setOpen={(e: boolean) => {
        setOpen(e);
        setIsMerchantId(false);
      }}
    >
      <form onSubmit={handleSubmit(createLocationReg)}>
        <Typography
          sx={{
            fontFamily: "var(--font-medium)",
            width: "max-content",
            borderBottom: "2px solid #22356D",
            marginTop: "10px",
          }}
        >
          {t("locationmanager.Location Settings")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginTop: "10px",
          }}
        >
          <Box sx={{ ...common, ...textBetween }}>
            <p>{t("locationmanager.Location Name")}</p>
            <input
              type="text"
              placeholder="Pererenan"
              {...register("location_name", { required: true })}
              style={{
                width: "160px",
                height: "27px",
                border: "1px solid #EEEEFE",
                borderRadius: "7px",
                fontFamily: "var(--font-regular)",
              }}
            />
          </Box>
          <Box sx={{ ...common, ...textBetween }}>
            <p>Region</p>
            <Box width={"160px"}>
              <SelectRegionBox
                text={"Select"}
                value={region?.region_name}
                setValue={(value: region) => setRegion(value)}
                arr={regionsData}
              />
            </Box>
          </Box>
          <Box sx={{ ...common, ...textBetween }}>
            <p>{t("locationmanager.Currency")}</p>
            <CurrencySelector
              setIsopen={() => console.log()}
              inHeight="auto"
              width="150px"
              selected={currency}
              setCurrency={setCurrency}
            />
          </Box>
          <Box sx={{ ...common, ...textBetween }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px!important",
                alignItems: "center",
              }}
            >
              <p>{t("MerchantId")}</p>
              <label className="switch">
                <input
                  checked={isMerchantId}
                  onChange={() => setIsMerchantId(!isMerchantId)}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </Box>
            {isMerchantId && (
              <input
                type="text"
                {...register("merchant_id")}
                placeholder={"merchant id"}
                style={{
                  width: "160px",
                  height: "33px",
                  color: "#22356D",
                  border: "1px solid #EEEEFE",
                  borderRadius: "7px",
                  fontFamily: "var(--font-regular)",
                }}
              />
            )}
          </Box>

          <Box sx={{ ...common, ...textBetween }}>
            <p>{t("common.Payment Methods")}</p>
            <Box
              width={"160px"}
              position={"relative"}
              onClick={() => setShowModel(true)}
            >
              <input
                type="text"
                value={paymentsda}
                placeholder={"credit card"}
                style={{
                  width: "100%",
                  height: "33px",
                  color: "#22356D",
                  border: "1px solid #EEEEFE",
                  borderRadius: "7px",
                  fontFamily: "var(--font-regular)",
                }}
                readOnly
              />
              <BsChevronDown
                style={{
                  position: "absolute",
                  top: "9.5px",
                  right: "8.5px",
                  fontSize: "0.8rem",
                }}
              />
            </Box>
          </Box>
          <Box sx={{ ...common, ...textBetween }}>
            <p>{t("locationmanager.Latitude & Longitude")}</p>
            <input
              type="text"
              placeholder="-8.634006564440663, 115.13981591540043"
              {...register("georeferenced", { required: true })}
              style={{
                width: "269px",
                height: "27px",
                border: "1px solid #EEEEFE",
                borderRadius: "7px",
                fontFamily: "var(--font-regular)",
              }}
            />
          </Box>
        </Box>
        {text !== "Creating" && (
          <Box>
            <TabContext value={value}>
              <Box>
                <TabList
                  onChange={handleSelectTab}
                  aria-label="people list"
                  TabIndicatorProps={{ sx: { backgroundColor: "#22356D" } }}
                  sx={{
                    ".MuiTab-root": {
                      paddingBottom: "0px",
                      textTransform: "capitalize",
                      paddingInline: "0px",
                      marginRight: "15px",
                      minWidth: 0,
                    },
                    ".Mui-selected": {
                      color: "#22356D !important",
                      fontFamily: "var(--font-semibold)",
                    },
                  }}
                >
                  {/* <Tab
                    label={`${t('locationmanager.Location Owner')} [${
                      filterUsersByRole(
                        data?.LocationPermissions,
                        "LOCATION_OWNER"
                      )?.length
                    }]`}
                    value="1"
                    sx={{ fontFamily: "var(--font-regular)" }}
                  /> */}
                  <Tab
                    label={`${t("locationmanager.Staff Manager")} [${
                      filterUsersByRole(
                        data?.LocationPermissions,
                        "STAFF_MANAGER"
                      )?.length
                    }]`}
                    value="1"
                    sx={{ fontFamily: "var(--font-regular)" }}
                  />
                  <Tab
                    label={`${t("locationmanager.Staff")} [${
                      filterUsersByRole(data?.LocationPermissions, "STAFF")
                        ?.length
                    }]`}
                    value="2"
                    sx={{ fontFamily: "var(--font-regular)" }}
                  />
                </TabList>
              </Box>
              {/* -------- Location Owner ------- */}
              {/* <TabPanel value="1" sx={{ padding: "0px 0" }}>
                <LocationOwner
                  text={"Location Owner"}
                  data={filterUsersByRole(
                    data?.LocationPermissions,
                    "LOCATION_OWNER"
                  )}
                  setAddUser={setAddUser}
                  setOpen={setOpen}
                  adminPop={adminPop}
                  setData={setData}
                />
              </TabPanel> */}
              {/* ------ staff manager ----- */}
              <TabPanel value="1" sx={{ padding: "0px 0" }}>
                <LocationOwner
                  updateLocation={updateLocationData}
                  location_id={data?.id}
                  text={"STAFF_MANAGER"}
                  data={filterUsersByRole(
                    data?.LocationPermissions,
                    "STAFF_MANAGER"
                  )}
                  setAddUser={setAddUser}
                  setOpen={setOpen}
                  adminPop={adminPop}
                  setData={setData}
                />
              </TabPanel>
              {/* ------ staff ------ */}
              <TabPanel value="2" sx={{ padding: "0px 0" }}>
                <LocationOwner
                  updateLocation={updateLocationData}
                  location_id={data?.id}
                  text={"STAFF"}
                  data={filterUsersByRole(data?.LocationPermissions, "STAFF")}
                  setAddUser={setAddUser}
                  setOpen={setOpen}
                  adminPop={adminPop}
                  setData={setData}
                />
              </TabPanel>
            </TabContext>
          </Box>
        )}
        <Box display={"flex"} justifyContent={"space-between"} marginTop={2}>
          <button
            style={{ ...style, color: "#22356D" }}
            type="button"
            onClick={handleClick}
          >
            {t("common.Delete")}
          </button>
          <button
            style={{ ...style, color: "white", background: "#22356D" }}
            type="submit"
          >
            {t("common.Save")}
          </button>
        </Box>
      </form>
      <EditPaymentType
        updatePayments={refetch}
        locationID={data?.id}
        arr={Paymentdata}
        text={"Payment Settings"}
        editField={""}
        open={showModel}
        setOpen={setShowModel}
      />
    </CustomModel>
  );
};

export default LocationAddModel;
