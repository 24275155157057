import { Box } from "@mui/material";
import React from "react";

const Loading = () => {
  return (
    <Box
      position={"absolute"}
      left={"65px"}
      sx={{
        width: { xs: "90%", sm: "99.5%" },
        height: { xs: "95%", sm: "99%" },
        zIndex: 9999,
        background: "rgba(0, 0, 0, 0.10)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        style={{ borderRadius: "7px", width: "40px", height: "40px" }}
        src="/images/Loadingic.gif"
      />
    </Box>
  );
};

export default Loading;
