import { useMediaQuery } from "@mui/material";
import React, { useContext, useEffect } from "react";
import MainContext from "src/context/MainContext";
import theme from "src/theme";
import "../components/tableStyling.css";
import "./style.css";

const ChatSupport: React.FC = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const context = useContext(MainContext);
  const authenticated = context?.authenticated;

  useEffect(() => {
    // Function to load the scripts
    const loadScripts = () => {
      // Check if jQuery is already loaded
      if (!document.querySelector("#jquery")) {
        const jQueryScript = document.createElement("script");
        jQueryScript.src = "https://support.bookandgo.app/js/min/jquery.min.js";
        jQueryScript.async = true;
        jQueryScript.id = "jquery";

        jQueryScript.onload = () => {
          // Load main.js after jQuery is loaded
          if (!document.querySelector("#sbinit")) {
            const mainScript = document.createElement("script");
            mainScript.id = "sbinit";
            mainScript.src = "https://support.bookandgo.app/js/main.js";
            mainScript.async = true;
            document.body.appendChild(mainScript);
          }
        };
        document.body.appendChild(jQueryScript);
      }
    };

    if (!isMobile && authenticated) loadScripts();

    // Cleanup function to remove scripts on component unmount
    return () => {
      const jQueryScript = document.getElementById("jquery");
      const mainScript = document.getElementById("sbinit");
      if (jQueryScript) {
        document.body.removeChild(jQueryScript);
      }
      if (mainScript) {
        document.body.removeChild(mainScript);
      }
    };
  }, []);

  return null;
};

export default ChatSupport;
