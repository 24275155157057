import React from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import { createRoot } from "react-dom/client"; // Import createRoot
import "./index.css";
import { NextUIProvider } from "@nextui-org/react";
import App from "./App";
import MainProvider from "./context/MainProvider";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { SocketProvider } from './context/SocketProvider'
import "./i18n";
// Create a client
const queryClient = new QueryClient();

// Select the root element
const rootElement = document.getElementById("root");

// Check if rootElement is not null
if (!rootElement) throw new Error("Failed to find the root element");

// Create a root
const root = createRoot(rootElement);

// Render the app using the root
root.render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <NextUIProvider>
        <MainProvider>
          <SocketProvider initialUrl={`${process.env.REACT_APP_BACKEND_URL}/calendars/2/updates`}>
            <App />
          </SocketProvider>
        </MainProvider>
      </NextUIProvider>
    </ThemeProvider>
  </QueryClientProvider>
);
