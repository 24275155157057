import React, { useEffect, useState } from "react";
import {
  useGetAllLocationName,
  useGetAllRegionsLocation,
  useGetRegions,
} from "src/api/ClubAdmin";
import LocationAddModel from "../../components/Admin/LocationAddModel";
import ClubAdminPopUp from "../../components/Admin/ClubAdminPopUp";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { EditRegionModel } from "../Customers/EditFieldModel";
import { RxHamburgerMenu } from "react-icons/rx";
import { BsChevronDown } from "react-icons/bs";
import { IoMdAdd } from "react-icons/io";
import MainContext from "src/context/MainContext";
import { useTranslation } from "react-i18next";
import LocationModal from "./LocationModal";

const common = {
  display: "flex",
  alignItems: "center",
  fontFamily: "var(--font-regular)",
  color: "#22356D",
};

const regionStyle = {
  padding: "10px 12px 10px 12px",
  cursor: "pointer",
  background: "#889AB61A",
  justifyContent: "space-between",
  fontFamily: "var(--font-regular)",
  "&:hover": {
    background: "#22356D",
    color: "white",
  },
};

const LocationManager = ({ isSuperAdmin }: { isSuperAdmin?: boolean }) => {
  const { setMobileSideBar, setSelectedRegion, setNotification } =
    React.useContext(MainContext);
  const [locationDetail, setLocationDetail] = useState([]);
  const { t, i18n } = useTranslation();
  const [locationList, setLocationList] = useState([]);
  const [locationPop, setLocationPop] = useState(false);
  const [addLocation, setAddLocation] = useState(false);
  const [regions, setRegions] = useState<any>([]);
  const [region, setRegion] = useState("All Regions");
  const [selected, setSelected] = useState("All Locations");
  const [showRel, setShowRel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [openPop, setOpenPop] = useState(false);
  const [addUser, setAddUser] = useState("");
  const [data, setData] = useState<any>();
  const LocalPerm: any = localStorage.getItem("user");
  const LocalUser: any = JSON.parse(LocalPerm);
  const IsStaffRole =
    LocalUser?.role?.name === "STAFF" ||
    LocalUser?.role?.name === "STAFF_MANAGER";
  const {
    data: AllRegions,
    isSuccess: RegionsSuccess,
    isError: RegionsError,
    refetch: RegionsRefetch,
  } = useGetRegions();
  const {
    data: AllLocations,
    isSuccess: AllLocationsSuccess,
    isError: AllLocationsError,
    refetch: AllLocationsRefetch,
  } = useGetAllRegionsLocation();
  const {
    data: LocationName,
    isSuccess: LocationNameSuccess,
    isError: LocationNameError,
    refetch: LocationNameRefetch,
  } = useGetAllLocationName();

  //getAllLocationsDetails
  useEffect(() => {
    if (AllLocationsSuccess) {
      setLocationDetail(AllLocations);
    } else {
      setLocationDetail([]);
    }
  }, [AllLocations, AllLocationsSuccess, AllLocationsError]);

  //getAllLocationNames
  useEffect(() => {
    if (LocationNameSuccess) {
      const newData = convertDataFormat(LocationName);
      const sortedData = newData?.locations?.sort(
        (a: any, b: any) => a?.sequence - b?.sequence
      );
      setLocationList(sortedData);
    } else {
      setLocationList([]);
    }
  }, [LocationName, LocationNameSuccess, LocationNameError]);

  //for filtering by location clicking
  const filterClub = (item: string) => {
    setSelected(item);
    if (item === "All Locations") {
      setLocationDetail(AllLocations);
    } else {
      const filtered = AllLocations?.filter(
        (loc: any) => loc?.location_name === item
      );
      setLocationDetail(filtered);
    }
  };

  useEffect(() => {
    if (selected === "All Locations") {
      setLocationDetail(AllLocations);
    }
  }, [selected]);

  useEffect(() => {
    IsStaffRole &&
      setNotification({
        message: "You don’t have permission to view this page.",
        subMessage: "",
        type: "error",
        isShow: true,
      });
  }, [IsStaffRole]);

  const handleRegion = (item: string) => {
    setRegion(item);
    setShowRel(false);
    if (item === "All Regions") {
      const newData = convertDataFormat(LocationName);
      const sortedData = newData?.locations?.sort(
        (a: any, b: any) => a?.sequence - b?.sequence
      );
      setLocationList(sortedData);
      setLocationDetail(AllLocations);
    } else {
      const newData = convertDataFormat(LocationName);
      const filtered = newData?.locations?.filter(
        (name: any) => name.region_name === item
      );
      const sortedData = filtered?.sort(
        (a: any, b: any) => a?.sequence - b?.sequence
      );
      setLocationList(sortedData);
      const filteredLocations = AllLocations?.filter(
        (loc: any) => loc?.region?.region_name === item
      );
      setLocationDetail(filteredLocations);
    }

    setSelected("All Locations");
  };

  const filterUsersByRole = (userData: any, roleName: string) => {
    return userData?.filter((user: any) => user.user?.role.name === roleName);
  };

  useEffect(() => {
    if (RegionsSuccess) {
      setRegions(AllRegions);
    } else {
      setRegions([]);
    }
  }, [AllRegions, RegionsSuccess, RegionsError]);

  useEffect(() => {}, [
    region,
    selected,
    locationDetail,
    locationList,
    locationPop,
  ]);

  const openMobileSidebar = (event: any) => {
    event.stopPropagation();
    setMobileSideBar(true);
  };

  return (
    <Box
      marginBottom={"3rem"}
      height={locationDetail?.length < 4 ? "90vh" : ""}
      sx={
        isSuperAdmin
          ? {}
          : {
              paddingLeft: { lg: "4rem", xl: "4rem" },
              paddingInline: { xs: "20px", sm: "20" },
            }
      }
    >
      <Typography
        sx={{
          paddingTop: "2.5rem",
          textAlign: { xs: "left", sm: "left", md: "center" },
          fontFamily: "var(--font-medium)",
          color: "#22356D",
          "@media (max-width: 900px)": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "15px",
          },
          fontSize: { xs: "1.2rem", sm: "1.2rem", md: "1.3rem" },
        }}
      >
        <RxHamburgerMenu
          onClick={openMobileSidebar}
          className="MobileHamburger"
        />
        <span>{t("locationmanager.Location Manager")}</span>
      </Typography>
      {/* ------ tabslider for selecting regions ------- */}
      <Box
        sx={{
          ...common,
          "@media (max-width: 900px)": {
            textAlign: "left",
            display: "grid",
            gap: "15px",
          },
        }}
        gap={2}
        marginBlock={4}
      >
        <Box position={"relative"}>
          <Box
            className="filter"
            sx={{ ...common, gap: "10px" }}
            onClick={() => setShowRel((prev) => !prev)}
          >
            <img
              src="/images/view1.png"
              style={{ width: "22px", height: "22px" }}
              alt="calender"
            />
            <Typography
              sx={{ fontFamily: "var(--font-regular)", fontSize: "1.1rem" }}
            >
              {region === "All Regions"
                ? t("locationmanager.All Regions")
                : region}
            </Typography>
            <BsChevronDown style={{ color: "#22356D", strokeWidth: "0.9px" }} />
          </Box>
          {showRel && (
            <Box
              position={"absolute"}
              sx={{
                background: "white",
                width: "204px",
                boxShadow: "0px 4px 4px 0px #00000012",
                padding: "7px",
                border: "1px solid #EEEEFE",
                borderRadius: "7px",
                zIndex: "999",
              }}
            >
              {/* showing all regions */}
              <Box
                id="table-person"
                sx={{
                  border: "1px solid #EEEEFE",
                  borderRadius: "7px",
                  boxShadow: "none !important",
                  marginTop: "0px !important",
                  maxHeight: "188.4px",
                }}
              >
                {/* selected region */}
                <Box
                  sx={{ padding: "10px 0px 10px 15px" }}
                  onClick={() => handleRegion("All Regions")}
                >
                  {region === "All Regions"
                    ? t("locationmanager.All Regions")
                    : region}
                </Box>
                {/* list of region */}
                <Box
                  sx={{
                    background: "#889AB61A",
                    padding: "10px 0px 10px 15px",
                    cursor: "pointer",
                    "&:hover": { background: "#22356D", color: "white" },
                  }}
                  key={0}
                  onClick={() => handleRegion("All Regions")}
                >
                  {t("locationmanager.All Regions")}
                </Box>
                <Box sx={{ borderRadius: "0 !important" }}>
                  {regions?.map((item: any) => (
                    <Box
                      sx={{ ...regionStyle, textTransform: "capitalize" }}
                      key={item.id}
                    >
                      {" "}
                      <p
                        onClick={() => {
                          setSelectedRegion(item);
                          handleRegion(item.region_name);
                        }}
                      >
                        {item.region_name}
                      </p>
                    </Box>
                  ))}
                </Box>
              </Box>

              <button
                style={{
                  width: "99px",
                  height: "27px",
                  borderRadius: "7px",
                  textAlign: "center",
                  border: "1px solid #EEEFEE",
                  boxShadow: "0px 4px 4px 0px #00000012",
                  marginBlock: "15px",
                  fontSize: "0.9rem",
                  color: "#22356DB2",
                }}
                onClick={() => {
                  setEditModel(true);
                  setShowRel(false);
                }}
              >
                {t("locationmanager.Edit Regions")}
              </button>
            </Box>
          )}
        </Box>
        {/* tab slider of location */}
        <Box>
          <Box
            fontFamily={"var(--font-medium)"}
            sx={{
              ...common,
              gap: "5px",
              flexWrap: "wrap",
              borderRadius: "7px",
              boxShadow: "0px 4px 4px 0px #0000000D inset",
              padding: "5px",
            }}
          >
            <button
              style={{
                padding: "0px 20px",
                background: selected === "All Locations" ? "white" : "",
                borderRadius: "7px",
                color: selected === "All Locations" ? "#22356D" : "#22356D99",
              }}
              onClick={() => filterClub("All Locations")}
            >
              {t("locationmanager.All Locations")}
            </button>
            {locationList?.map((item: any, index: number) => (
              <button
                style={{
                  textTransform: "capitalize",
                  padding: "0px 20px",
                  marginBottom: "5px",
                  background: selected === item.location_name ? "white" : "",
                  borderRadius: "7px",
                  color:
                    selected === item.location_name ? "#22356D" : "#22356D99",
                }}
                onClick={() => filterClub(item.location_name)}
                key={index}
              >
                {item.location_name}
              </button>
            ))}
            <IoMdAdd
              style={{
                padding: "0px 20px",
                color: "#22356D99",
                width: "60px",
                cursor: "pointer",
              }}
              onClick={() => {
                setAddLocation(true);
              }}
            />
          </Box>
        </Box>
      </Box>
      {/* ----- end ----- */}

      {/* --- cards of locations --- */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { sm: "repeat(2, 1fr)", md: "repeat(3, 1fr)" },
          gap: "18px",
          gridAutoRows: "auto",
          alignItems: "start",
          "@media (max-width: 600px)": {
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "center",
          },
        }}
      >
        {locationDetail?.map((item: any, index: number) => (
          <LocationModal
            AllLocationsRefetch={AllLocationsRefetch}
            filterUsersByRole={filterUsersByRole}
            index={index}
            item={item}
            setAddUser={setAddUser}
            setData={setData}
            setLocationPop={setLocationPop}
            setOpenPop={setOpenPop}
          />
        ))}
      </Box>
      {/* Model for add club admin and update */}

      <EditRegionModel
        type="location"
        updateLocationList={LocationNameRefetch}
        updateLocationName={AllLocationsRefetch}
        regionName={RegionsRefetch}
        arr={locationList}
        text={"Edit Locations"}
        editField={""}
        open={addLocation}
        setOpen={setAddLocation}
      />

      <ClubAdminPopUp
        text={addUser}
        data={data}
        open={openPop}
        setOpen={setOpenPop}
        updateLocation={LocationNameRefetch}
        updateLocationData={AllLocationsRefetch}
        locationList={locationList}
      />
      {/* Model for add and update */}
      <LocationAddModel
        text={addUser}
        data={data}
        setData={setData}
        open={locationPop}
        setOpen={setLocationPop}
        adminPop={setOpenPop}
        setAddUser={setAddUser}
        updateLocation={LocationNameRefetch}
        updateLocationData={AllLocationsRefetch}
      />

      <EditRegionModel
        type="region"
        regionName={RegionsRefetch}
        updateLocationList={LocationNameRefetch}
        updateLocationName={AllLocationsRefetch}
        arr={regions}
        text={"Edit Regions"}
        editField={""}
        open={editModel}
        setOpen={setEditModel}
      />
    </Box>
  );
};

export default LocationManager;

const convertDataFormat = (data: any) => {
  let newLocations: any = [];

  data.forEach((region: any) => {
    if (region.locations.length > 0) {
      region.locations.forEach((location: any) => {
        newLocations.push({
          Region_id: region.id,
          sequence: location.sequence,
          region_name: region.region_name,
          id: location.id,
          location_name: location.location_name,
        });
      });
    }
  });

  return { locations: newLocations };
};
