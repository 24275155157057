import { Box, Paper, Popover, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react'
import MainContext from 'src/context/MainContext';

const NotificationToast = () => {
    const {Notification,setNotification} = useContext(MainContext);

    useEffect(() => {
      if(Notification.isShow){
        setTimeout(()=>{
          setNotification({message:'',type:'',subMessage:'',isShow:false})
          },5000)
      }   
    }, [Notification]);
    

  return (
    <Popover
    className='NotificationPopover'
   open={Notification.isShow}  
   onClose={()=> setNotification({message:'',type:'',subMessage:'',isShow:false})}
   anchorOrigin={{
    vertical: 'top',
    horizontal: 'right',
  }}
>
    {Notification.isShow &&
      <Paper sx={{
        backgroundColor:Notification.type==="success" ?'white':' var(--Light-Error-background, #FFF5F3)',
        padding:'20px',
        border: Notification.type==="success"?'1px solid var(--Light-Sucess-border, #48C1B5)':'1px solid var(--Light-Error-border, #F4B0A1)',
        borderRadius:"12px !important",
        width:'300px'
      }}  elevation={2}>
  <Box >
      {/* <RxCross2 onClick={()=> setNotification({message:'',type:'',subMessage:'',isShow:false})} style={{display:'flex',justifyContent:'flex-end'}}/> */}
      <Box  sx={{display:'flex',flexDirection:'row',gap:'10px'}}>
        <img style={{width:'24px',height:'24px'}} src={Notification.type==="success"?"/images/GreenTick.png":Notification.type==="error"?"/images/RedCross.png":''}/>
        <Box>
          <Typography sx={{fontFamily:'var(--font-semibold)',fontSize:'15px'}}>{Notification.message}</Typography>
          <Typography sx={{fontFamily:'var(--font-regular)',fontSize:'14px'}}>{Notification.subMessage}</Typography>

        </Box>
  </Box>
      </Box>
</Paper>
    }
</Popover>
  )
}

export default NotificationToast