import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { BsChevronDown } from "react-icons/bs";

interface MyDropdownProps {
  text: string;
  arr: string[];
  selected: string[];
  setSelected: any;
}

const MyDropdown: React.FC<MyDropdownProps> = ({
  text,
  arr,
  selected,
  setSelected,
}) => {
  const [open, setOpen] = useState(false);

  const handleShow = (event: any) => {
    event.stopPropagation();
    setOpen((prev) => !prev);
  };

  const handleSelection = (event: any, item: string) => {
    event.stopPropagation();

    if (text === "role") {
      setSelected(selected.includes(item) ? [] : [item]);
    } else if (
      text === "Access" ||
      text === "Capabilties" ||
      text === "Permission"
    ) {
      if (selected.includes(item)) {
        setSelected((prev: any) =>
          prev.filter((option: any) => option !== item)
        );
      } else {
        setSelected((prev: any) => [...prev, item]);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", () => {
      setOpen(false);
    });
  }, []);

  return (
    <Box width={"160px"} position={"relative"}>
      <Box position={"relative"} onClick={handleShow} width={"160px"}>
        <input
          type="text"
          placeholder={`Select ${text}`}
          style={{
            width: "100%",
            height: "27px",
            border: "1px solid #EEEEFE",
            borderRadius: "7px",
            fontFamily: "var(--font-regular)",
          }}
          value={selected?.join(", ")}
          readOnly
        />
        <BsChevronDown
          style={{
            position: "absolute",
            top: "6.5px",
            right: "5.5px",
            fontSize: "0.8rem",
          }}
        />
      </Box>
      {open && (
        <Box
          position={"absolute"}
          left={0}
          zIndex={9}
          width={"-webkit-fill-available"}
          sx={{
            background: "white",
            borderBottomLeftRadius: "7px",
            borderBottomRightRadius: "7px",
            top: { xs: "26px", sm: "0px" },
          }}
        >
          {arr.map((item: any, index: number) => (
            <Box
              key={index}
              sx={{
                background: selected.includes(item) ? "#22356DB2" : "#889AB61A",
                color: selected.includes(item) ? "white" : "#22356D",
                padding: "10px",
                cursor: "pointer",
                "&:hover": {
                  background: "#22356DB2",
                  color: "#FFFFFF",
                },
              }}
              display={"flex"}
              justifyContent={"space-between"}
              gap={"20px"}
              onClick={(event) => handleSelection(event, item)}
            >
              <p style={{ fontFamily: "var(--font-regular)" }}>{item}</p>
              <Box>
                <label className="container">
                  <input
                    type="checkbox"
                    checked={selected.includes(item) ? true : false}
                  />
                  <span className="mark" style={{ left: "-9px" }}></span>
                </label>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default MyDropdown;
