import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import { Select, SelectItem } from "@nextui-org/react";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaAngleDown } from "react-icons/fa";
import { TfiWorld } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import { useGetMyDetails } from "src/api/CalendarBooking";
import { updatePassword } from "src/api/ClubAdmin";
import { useGetAllCLubs } from "src/api/SuperAdmin";
import MainContext from "src/context/MainContext";
import "moment/locale/ru";
import "moment/locale/es";

interface FormInput {
  currentPassword: string;
  newPassword: string;
}
const TopUserInfo = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { setNotification } = useContext(MainContext);
  const { register, reset, handleSubmit } = useForm<FormInput>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const { LoginData, setLoginData } = useContext(MainContext);
  const {
    data: ClubsData,
    isSuccess: ClubsSucces,
    isError: ClubError,
    refetch: ClubRefetch,
  } = useGetAllCLubs();
  const {
    data: UserDetails,
    isSuccess: UserSuccess,
    isError: UserError,
  } = useGetMyDetails();
  const [AllClubs, setAllclubs] = useState<any[]>([]);
  const [selectedClub, setSelectedClub] = useState<any>();

  const changleLanguage = async (lng: number) => {
    const locale = Number(Lang) === 1 ? "en" : Number(Lang) === 2 ? "es" : "ru";
    //   moment.locale(locale)
    window.localStorage.setItem("Lng", JSON.stringify(lng));
    i18n.changeLanguage(locale);
  };

  const Lang = window.localStorage.getItem("Lng") || 1;
  const club_id = JSON.parse(
    localStorage.getItem("Club_id") || JSON.stringify("")
  );
  const user = JSON.parse(localStorage.getItem("user") || JSON.stringify({}));

  const handleClubChange = (data: any) => {
    localStorage.setItem("location_restriction", data.location_restriction);
    localStorage.setItem("Club_id", data?.id);
    setSelectedClub(data);
    setTimeout(() => {
      window.location.reload();
    }, 600);
  };

  useEffect(() => {
    if (!!AllClubs?.length && club_id) {
      const PreselectedClub = AllClubs?.filter(
        (item: any) => item?.id === club_id
      )[0];
      setSelectedClub(PreselectedClub);
    }
  }, [club_id, AllClubs]);

  useEffect(() => {
    if (user?.role.name === "SUPER_ADMIN") {
      i18n.changeLanguage("en");
      ClubRefetch();
    }
  }, []);

  useEffect(() => {
    if (UserSuccess) {
      setLoginData(UserDetails);
    }
  }, [UserDetails, UserSuccess, UserError]);

  const handleClose = () => {
    reset();
    setIsOpen(false);
  };

  const handleLogout = () => {
    navigate("/logout");
  };

  const onSubmit = async (apiData: any) => {
    try {
      const response = await updatePassword(apiData);
      setNotification({
        isShow: true,
        message: response.message,
        subMessage: "",
        type: "success",
      });
      handleClose();
    } catch (error: any) {
      setNotification({
        isShow: true,
        message: error,
        subMessage: "",
        type: "error",
      });
    }
  };

  useEffect(() => {
    window.addEventListener("click", (e) => {
      e.stopPropagation();
      setIsClicked(false);
    });
  }, []);

  const handleOpenDr = (e: any) => {
    e.stopPropagation();
    setIsClicked(!isClicked);
  };

  useEffect(() => {
    if (user?.role.name === "SUPER_ADMIN" && ClubsSucces) {
      setAllclubs(ClubsData);
      if (!club_id) {
        localStorage.setItem("Club_id", ClubsData[0]?.id);
        localStorage.setItem(
          "location_restriction",
          ClubsData[0]?.location_restriction
        );
        setSelectedClub(ClubsData[0]);
        setTimeout(() => {
          window.location.reload();
        }, 600);
      }
    } else {
      setAllclubs([]);
    }
  }, [ClubsData, ClubsSucces, ClubError]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: isClicked ? "start" : "center",
        gap: "5px",
        justifyContent: "flex-end",
        flexWrap: "wrap",
        marginTop: "10px",
      }}
    >
      {user?.role.name === "SUPER_ADMIN" && (
        <Select
          classNames={{
            mainWrapper: "!bg-red-600",
          }}
          aria-label="Select Club"
          placeholder="Select club"
          labelPlacement="outside"
          size="sm"
          className="max-w-xs min-w-32"
          disableSelectorIconRotation
          // style={{width:'50%'}}
          selectedKeys={[String(selectedClub?.id)]}
          selectorIcon={<FaAngleDown />}
        >
          {AllClubs?.map((item: any) => (
            <SelectItem
              onClick={() => handleClubChange(item)}
              key={item?.id}
              value={item}
              style={{
                fontFamily: "var(--font-regular) !important",
                color: "#22356DB2",
              }}
            >
              {item?.club_name}
            </SelectItem>
          ))}
        </Select>
      )}
      {user?.role.name !== "SUPER_ADMIN" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <TfiWorld style={{ color: "#22356DB2", marginRight: "2px" }} />
          <Select
            style={{
              width: "70px",
              fontFamily: "var(--font-regular) !important",
              color: "#22356DB2",
              fontSize: "13px",
            }}
            aria-label="Select Lang"
            placeholder="Select Lang"
            labelPlacement="outside"
            size="sm"
            className="max-w-xs "
            disableSelectorIconRotation
            selectedKeys={[String(Lang)]}
            selectorIcon={<FaAngleDown />}
          >
            {[1, 2, 3]?.map((item: number) => (
              <SelectItem
                onClick={(e) => changleLanguage(item)}
                key={item}
                value={item}
                style={{
                  fontFamily: "var(--font-regular) !important",
                  color: "#22356DB2",
                }}
              >
                {item === 1 ? "EN" : item === 2 ? "ES" : "RU"}
              </SelectItem>
            ))}
          </Select>
        </Box>
      )}
      <Box
        sx={{
          display: { xs: "flex", md: "flex" },
          flexDirection: "row",
          gap: "5px",
        }}
      >
        {user?.role.name !== "SUPER_ADMIN" && (
          <Typography
            sx={{
              textTransform: "capitalize",
              fontSize: "14px",
              background: "white",
              color: "#22356D",
              fontFamily: "var(--font-semibold)",
              paddingX: "8px",
              paddingTop: "4px",
              borderRadius: "7px",
              boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px inset",
              height: "28px",
            }}
          >
            {user?.club_name}
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            position: "relative",
          }}
        >
          <Typography
            onClick={(e) => handleOpenDr(e)}
            sx={{
              textTransform: "capitalize",
              fontSize: "14px",
              background: "white",
              color: "#22356D",
              fontFamily: "var(--font-semibold)",
              paddingX: "8px",
              paddingTop: "4px",
              borderRadius: "7px",
              boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px inset",
              height: "27px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {user?.full_name} -{" "}
            {user?.role.name.replace("_", " ").toLowerCase()}
            <FaAngleDown
              style={{ transform: isClicked ? "rotate(180deg)" : "" }}
            />
          </Typography>
          {user?.role.name !== "SUPER_ADMIN" && isClicked && (
            <Box
              sx={{
                display: "flex",
                position: "absolute",
                flexDirection: "column",
                background: "white",
                padding: "10px",
                borderRadius: "8px",
                gap: "5px",
                width: "-webkit-fill-available",
                top: "35px",
              }}
            >
              <Typography
                onClick={() => setIsOpen(true)}
                sx={{
                  color: "#22365D",
                  opacity: "0.7",
                  fontSize: "14px",
                  fontFamily: "var(--font-regular)",
                  cursor: "pointer",
                }}
              >
                {t("common.Change Password")}
              </Typography>
              <Typography
                onClick={handleLogout}
                sx={{
                  cursor: "pointer",
                  color: "#22365D",
                  opacity: "0.7",
                  fontSize: "14px",
                  fontFamily: "var(--font-regular)",
                }}
              >
                {t("common.Log Out")}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Dialog
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{
          ".MuiDialog-paper": {
            maxWidth: "auto",
            paddingInline: { xs: "10px", sm: "1rem" },
            width: { xs: "auto !important", md: "600px !important" },
          },
        }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <DialogContent>
          <Typography
            sx={{
              color: "#22356D",
              fontFamily: "var(--font-semibold)",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            {t("locationmanager.Admin Info")}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                background: "#889AB61A",
                padding: "10px 20px",
                borderRadius: "7px",
                border: "0.5px solid #EEEFEE",
                boxShadow: "inset 0 0 10px #0000000D",
                marginBottom: "10px",
                gap: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <input
                  style={{
                    color: "#22356D",
                    fontFamily: "var(--font-regular)",
                  }}
                  readOnly
                  value={user?.full_name}
                />
                <span
                  style={{
                    color: "#22356D",
                    fontFamily: "var(--font-regular)",
                    background: "#0000000D",
                    padding: "0 4px",
                    borderRadius: "8px",
                    textTransform: "capitalize",
                  }}
                >
                  {user?.role?.name?.replace("_", " ")}
                </span>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <input
                  style={{
                    color: "#22356D",
                    fontFamily: "var(--font-regular)",
                  }}
                  readOnly
                  value={user?.phone_number}
                />
                <input
                  style={{
                    color: "#22356D",
                    fontFamily: "var(--font-regular)",
                  }}
                  readOnly
                  value={user?.email}
                />
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "#22356D",
                  fontFamily: "var(--font-semibold)",
                  fontSize: "16px",
                  marginTop: "5px",
                  marginBottom: "15px",
                  textDecoration: "underline",
                  textUnderlineOffset: "6px",
                }}
              >
                {t("common.Change Password")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#22356D",
                    fontFamily: "var(--font-regular)",
                    fontSize: "16px",
                    marginTop: "5px",
                    textUnderlineOffset: "6px",
                  }}
                >
                  {t("common.Current Password")}
                </Typography>
                <input
                  type="password"
                  {...register("currentPassword", { required: true })}
                  style={{
                    height: "30px",
                    border: "1px solid #EEEEFE",
                    fontFamily: "var(--font-regular)",
                    color: "#22356DB2",
                  }}
                  placeholder={t("common.Enter Password")}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    color: "#22356D",
                    fontFamily: "var(--font-regular)",
                    fontSize: "16px",
                    marginTop: "5px",
                    textUnderlineOffset: "6px",
                  }}
                >
                  {t("common.New Password")}
                </Typography>
                <input
                  type="password"
                  {...register("newPassword", { required: true })}
                  style={{
                    height: "30px",
                    border: "1px solid #EEEEFE",
                    fontFamily: "var(--font-regular)",
                    color: "#22356DB2",
                  }}
                  placeholder={t("common.Enter new password")}
                />
              </Box>
            </Box>
            <Grid
              marginTop={"40px"}
              container
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Button
                onClick={handleClose}
                style={{
                  textTransform: "unset",
                  fontFamily: "var(--font-semibold)",
                  width: "104px",
                  height: "37px",
                  color: "#22356D",
                  backgroundColor: "white",
                  fontSize: "16px",
                  padding: "10px 30px",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
                }}
              >
                {t("common.Cancel")}
              </Button>
              <Button
                type="submit"
                style={{
                  textTransform: "unset",
                  fontFamily: "var(--font-semibold)",
                  color: "white",
                  backgroundColor: "#22356D",
                  lineHeight: "14px",
                  width: "92px",
                  height: "37px",
                  fontSize: "16px",
                  pointerEvents: "auto",
                }}
              >
                {t("common.Save")}
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default TopUserInfo;
